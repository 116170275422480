
const imgUrls = [

    {id: 1, url: 'https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/Profileimg%2F080db08f-651d-469e-91f8-57f798b96e7b.jpg?alt=media&token=057f0e73-83c6-4b0e-99c9-b8f6bce0574c'},
    {id: 2, url: 'https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/Profileimg%2Faef9f910-4249-4d05-8214-32dffb5227bd.jpg?alt=media&token=78f024c5-fec5-4f3f-b803-87dd8489bf29'},
    {id: 3, url: 'https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/Profileimg%2Fbbf8fd2f-6923-4ee1-8320-0175b8c83953.jpg?alt=media&token=1d7fd5ba-2f7b-4a58-abf5-1b7ad88ce6fb'},
    {id: 4, url: 'https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/Profileimg%2Fc19aa9c3-8dac-4a2a-b957-ab4058618025.jpg?alt=media&token=ed2113c5-d364-4ec4-bd9d-2efcd455d327'},
    {id: 5, url: 'https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/Profileimg%2Fc8c7ce44-992e-4b8a-870f-fa64b000f530.jpg?alt=media&token=4cf10ebd-4e0b-461b-92d5-a5a439757622'},
    {id: 6, url: 'https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/Profileimg%2Fd3fa5d5c-c4d9-474b-8c8f-6f6e5915246f.jpg?alt=media&token=e5eb5ed1-811c-4f43-bbf2-ad49b01089d5'},
    {id: 7, url: 'https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/Profileimg%2Fd676e6a8-b060-4212-ba63-0a64b97d4b97.jpg?alt=media&token=27b3bc27-af00-4f56-ab50-c4cef3526868'},
    {id: 8, url: 'https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/Profileimg%2Fdba20b3e-1cd2-4555-968f-5620955eee8f.jpg?alt=media&token=55daf9c9-260b-4f77-821e-b4e7027b3082'},
    {id: 9, url: 'https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/Profileimg%2Ff9fc6d3b-46df-49cb-b1ba-04557584f292.jpg?alt=media&token=ce896b22-9c3a-472b-8c5d-3fe5560da57b'},
    {id: 10, url: 'https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/Profileimg%2Ffb6127f4-188e-439e-b345-bb7c712601a9.jpg?alt=media&token=e84d7ae1-2a02-4bc8-8a9e-cf6102016d21'}

]

function getRandomImgLocal(){

    const randomIndex = Math.floor(Math.random() * imgUrls.length);

    const randomObject = imgUrls[randomIndex];

    return randomObject.url
}

export const getRandomImg = getRandomImgLocal
import React from 'react'
import { useState, useRef, useEffect } from 'react'
import { API_URL } from '../apiConfig'
import ErrorDialog from './ErrorDialog'
import PrincipalButton from './PrincipalButton'
import Footer from './Footer'
import { useAuth } from '../Context/AuthContext'
import { analytics } from '../Firebase'
import { logEvent } from 'firebase/analytics'

function ExamIteml({ exam, finishExam }) {


    const [loading, setLoading] = useState(false)
    const [currentItem, setCurrentItem] = useState(0)
    const selectedAnsware = useRef(null)
    const answareTime = useRef(Date.now())
    const [error, setError] = useState(null)
    const { currentUser } = useAuth()

    const [animation, setanimate] = useState('rotate-y-45 opacity-80')
    const timeoutRef = useRef(null)

    useEffect(() => {

        const timeOut = setTimeout(() => {

            setanimate('')

        }, 100);

        return () => { clearTimeout(timeOut); }
    }, [])

    useEffect(() => {

        logEvent(analytics, 'question_change')

    },[currentItem])

    function handleAnimation() {

        setanimate('rotate-y-45 opacity-80')

        if (timeoutRef.current) clearTimeout(timeoutRef.current)

        timeoutRef.current = setTimeout(() => {

            setanimate('')

        }, 300);

    }

    function handleOnChange(answer) {
        selectedAnsware.current = answer
    }

    function handleCleanError() {
        setError(null)
    }

    async function setAnsware() {

        const answareTimeTemp = ( Date.now() - answareTime.current)

        const examItemDTO = {
            examExternalId: exam.externalId,
            examItemId: exam.examItems[currentItem].id,
            answerSelectedId: selectedAnsware.current.id,
            answareTime : answareTimeTemp
        }
        

        try {

            const response = await fetch(`${API_URL}/api/exam/setAnswer`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${currentUser.accessToken}` },
                    body: JSON.stringify(examItemDTO)
                });

                if (response.ok) {

                    const data = await response.json()

                    if (currentItem === exam.examItems.length - 1) {

                        //setCurrentItem(0)
        
                        finishExam(exam.externalId)
        
                    } else {
                        
                        answareTime.current = Date.now()
        
                        setCurrentItem((prevCurrentItem => prevCurrentItem + 1));
        
                    }
    
                    setLoading(false)
    
                    return data
    
                } else {
    
                    setError({ message: `${response.status} Ha ocurrido un error procesando su solicitud` })
    
                    setLoading(false)
    
                }

        } catch (error) {

            switch (error.message) {
                case 'Failed to fetch':
                    error.message = "Intentamos enviar respuesta, favor validar conexion"
                    break;

                default:
                    break;
            }

            setError(error)

        }

    }

    

    async function handleSubmit(e) {
        e.preventDefault()


        setLoading(true)

        //handleAnimation()

        await setAnsware()

        setLoading(false)
    }

    return (
        <>
            {error && <ErrorDialog error={error} handleCleanError={handleCleanError} secondsN={5} />}

            <div className='flex flex-col justify-center items-center  z-10 '>

                <div className={`flex flex-col p-5 justify-between m-2  mt-20 sm:mt-28   ${animation} transition-all duration-300 ease-in-out transform perspective-1000 font-Montserrat text-xl text-bluef border-2 rounded-xl pt-5 bg-white z-10 xl:w-[900px]`}>


                    <img loading='lazy' className='max-h-[400px] w-auto mx-auto bg-slate-300  rounded-xl z-10 border-yellowf  border-2' src={exam.examItems[currentItem].imageUrl} alt="image" />

                    <form className='mt-10 flex flex-col justify-between flex-grow' action="#" onSubmit={(e) => handleSubmit(e)}>
                        
                        <p className='text-center font-semibold'>{exam.examItems[currentItem].questionTitle}</p>
                        <div className="flex justify-center items-center mt-4 ">

                            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-5 ">

                                {
                                    exam.examItems[currentItem].question.answers.map((item => {
                                        return (
                                            <div key={item.id} className="flex items-center ">
                                                
                                                <input className="h-6  w-6" type="radio" id={item.option} name="answer" required onChange={() => { handleOnChange(item) }} />
                                                
                                                <label htmlFor={item.option} className="ml-2 w-full "><span className='text-yellowf'>{item.option} </span>{item.title}</label>
                                            </div>
                                        )
                                    }))

                                }

                            </div>
                        </div>

                        <div className='flex  justify-between items-center '>

                            <h4 className='text-lg text-gray-600'>{`${currentItem + 1} / ${exam.examItems.length}`}</h4>
                            <PrincipalButton loading={loading} textI={currentItem === exam.examItems.length - 1 ? 'Finalizar' : 'Siguiente'} textII={'Procesando'} type={'submit'} />
                        </div>

                    </form>
                </div>
                
            </div>

            
        </>

    )
}

export default ExamIteml
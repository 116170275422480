import React from 'react'
import { useState, useEffect } from 'react';

function InicialAnimationRotate({ children }) {

    const [animation, setanimate] = useState(false)



    useEffect(() => {


        const timeOut = setTimeout(() => {

            setanimate(true)

        }, 100);

        return () => clearTimeout(timeOut)

    }, [])

    return (
        <div className={`${animation ? 'rotate-y-0 opacity-100' : 'rotate-y-45 opacity-0'}   transition-all duration-300 ease-in-out transform perspective-1000`}>
            {children}
        </div>
    )
}

export default InicialAnimationRotate
import React, { useState } from 'react'
import EyeSlashSolid from '../Assets/eye-slash-solid.svg'
import EyeSolid from '../Assets/eye-solid.svg'

function AnswerContent({ answer }) {

    const [active, setActive] = useState(false)

    return (
        <>
            <img src={active ? EyeSolid : EyeSlashSolid} alt="" className='h-6 pr-2 hover:cursor-pointer' onClick={() => setActive(preState => !preState)}/>
            <p>{active && answer}</p>
        </>
    )
}

export default AnswerContent
import React, { useState } from 'react'
import logo from '../Assets/logo.svg'
import AppFrame from '../Components/AppFrame'
import iphoneAsset from '../Assets/iphoneAsset.svg'
import { useAuth } from '../Context/AuthContext'
import { useEffect, useRef } from 'react'
import CodeConfirmation from '../Components/CodeConfirmation'
import Footer from '../Components/Footer'
import PrincipalButton from '../Components/PrincipalButton'
import DominicanRepublic from '../Assets/DominicanRepublic.png'
import UniteStates from '../Assets/UniteStates.png'
import InicialAnimationRotate from '../Components/InicialAnimationRotate'

function Login() {


    useEffect(() => {

        document.title = `Login - Gliaco`

        setUpRecaptcha(handleSubmit)

    }, [])


    const { sigInUser, codeConfirmation, setUpRecaptcha, setDisplayName, sendReferralCode } = useAuth()

    const [phone, setPhone] = useState('')
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [error, setError] = useState(null)
    const inputRef = useRef('');

    function handleChange(event) {
        const inputType = event.nativeEvent.inputType;
        const inputPhone = event.target.value;

        if (inputType === 'deleteContentBackward' || inputType === 'deleteContentForward') {


            return;

        } else {

            const formattedPhone = formatPhoneNumber(inputPhone);

            inputRef.current.value = formattedPhone
        }

    }

    const formatPhoneNumber = (input) => {

        const cleaned = ('' + input).replace(/\D/g, '');


        if (cleaned.length < 3) {
            return cleaned;
        }

        if (cleaned.length >= 3 && cleaned.length < 6) {
            return '(' + cleaned.substring(0, 3) + ')-' + cleaned.substring(3);
        }

        if (cleaned.length >= 6) {
            return (
                '(' +
                cleaned.substring(0, 3) +
                ')-' +
                cleaned.substring(3, 6) +
                '-' +
                cleaned.substring(6)
            );
        }

        return input;
    };

    const handleOnClose = () => {
        setShow(false)
    }

    async function handleSubmit(e, option) {

        option && e.preventDefault()

        const phone2 = '+1' + inputRef.current.value.replace(/[()-]/g, '')


        try {

            setLoading(true)

            await sigInUser(phone2, window.recaptchaVerifier)

            setLoading(false)

            setShow(true)

        } catch (error) {
            switch (error.code) {
                case 'auth/invalid-phone-number':
                    setError('Telefono invalido')
                    break;
                case 'auth/quota-exceeded':
                    setError('Limite de intentos, intentar mas tarde')
                    break;
                case 'auth/user-disabled':
                    setError('Usuario inhabilitado')
                    break;
                case 'auth/internal-error':
                    setError('Sin conexion')
                    break;
                default:
                    setError('Intentar mas tarde')

            }
            setLoading(false)
        }
    }



    return (

        <>
            <AppFrame>



                <div className=' flex items-center justify-center h-full mt-40'>
                   
                    <img className='absolute h-[100vw] max-h-[500px] sm:ml-52 ' src='https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/ProjectImages%2FAcuarelaHappyAsset.jpg?alt=media&token=16446438-997f-4596-b847-53a37faba3ea' alt='Happy' />
                    
                    <InicialAnimationRotate>
                        <div className=' block border rounded-lg p-9 bg-white shadow-lg z-10 backdrop-blur-md '>

                            <img className='w-[150px] mx-auto' src={logo} alt='logo' />

                            <h1 className='font-Montserrat text-lg text-bluef mt-10'>Inicia o crea una cuenta</h1>

                            <form action="#" className='mt-5 ' onSubmit={(e) => handleSubmit(e, true)}>

                                <label className='block text-lg font-Montserrat text-bluef'>Telefono móvil</label>

                                <div className='flex flex-row items-center'>

                                    <div className=' flex flex-col'>
                                        <img src={DominicanRepublic} alt="" className='h-4 pr-2' />
                                    </div>


                                    <select name="cars" id="cars" className='border  rounded-l-full text-gray-400 h-11'>
                                        <option value="+ 1">+ 1 </option>
                                        <option value="+ 1">+ 1</option>
                                    </select>

                                    <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none'>
                                            <img className=' h-6 pl-1' src={iphoneAsset} alt='logo' />
                                        </div>
                                        <input onChange={handleChange} ref={inputRef} type="text" pattern="^\(\d{3}\)-\d{3}-\d{4}$" className='border w-44 h-11 pl-7 focus:ring-blue-500 rounded-r-full p-2 block' name='Phone Number' placeholder='(809)-845-8879' required></input>
                                    </div>

                                </div>

                                <p> {error && error} </p>
                                <div className='pt-5'>

                                    <input type='checkbox' className=' focus:ring-blue-500 focus:ring-1 '></input>
                                    <label className='font-Montserrat text-bluef pl-2'>Remember me</label>

                                </div>
                                <div className='flex w-full justify-center mt-5 '>
                                    <PrincipalButton loading={loading} textI={"Iniciar"} textII={'Procesando'} type={'submit'} />
                                </div>

                            </form>

                        </div>
                    </InicialAnimationRotate>


                    <div id="recapcha-container"></div>

                </div>
            </AppFrame>

            <CodeConfirmation phoneNumber={inputRef.current.value} show={show} handleOnClose={handleOnClose} codeConfirmation={codeConfirmation} setDisplayName={setDisplayName} sendReferralCode={sendReferralCode} />

        </>

    )
}

export default Login
import React from 'react'
import AppFrame from '../Components/AppFrame'

function PaymentCanceled() {
    return (
        <AppFrame>
            <div>PaymentCanceled</div>
        </AppFrame>

    )
}

export default PaymentCanceled
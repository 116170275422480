
import React, { useState } from 'react'
import messageAsset from '../Assets/messageAsset.svg'
import { useNavigate } from 'react-router-dom'
import userAsset from '../Assets/userAsset.svg'
import SetDisplayName from './SetDisplayName'
import { useAuth } from '../Context/AuthContext'
import PrincipalButton from './PrincipalButton'

function CodeConfirmation({ phoneNumber, show, handleOnClose, codeConfirmation, setDisplayName, sendReferralCode }) {


  const [code, setCode] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showDisplayName, setShowDisplayName] = useState(false)

  const { logout } = useAuth()

  const navigate = useNavigate()


  function handleChangeCode(event) {
    setCode(event.target.value)
  }

  const handleDisplayClose = () => {

    setShowDisplayName(false)

  }

  async function handleSubmit(e) {

    e.preventDefault()
    try {

      let userDisplayName = null


      setLoading(true)

      userDisplayName = await codeConfirmation(code)

      setLoading(false)


      if (userDisplayName !== null) {
        navigate('/')

      } else {

        setShowDisplayName(true)
      }

    } catch (error) {

      switch (error.code) {
        case 'auth/invalid-verification-code':
          setError('Codigo invalido')
          break;
        case 'auth/network-request-failed':
          setError('Sin conexion')
          break;
        case 'auth/internal-error':
          setError('Sin conexion')
          break;
        default:
          setError('Intentar mas tarde')
          break;
      }
      setLoading(false)
    }


  }

  if (!show) return null

  return (
    <>
      <div className='fixed flex inset-0 z-50 justify-center items-center bg-opacity-60 bg-slate-500 '>

        <div className=' block border rounded-lg p-7 bg-white shadow-lg '>

          <div className='flex  justify-end '>
            <button type='submit' onClick={handleOnClose} className='bg-orange-500 hover:bg-opacity-80  rounded-full text-white font-Montserrat font-bold pl-2 pr-2'>
              Cerrar
            </button>
          </div>

          <h1 className='font-Montserrat text-lg text-bluef mt-5'>Ingrese el codigo enviado a <br /> {phoneNumber}</h1>
          <p className='font-Montserrat text-md text-bluef mt-2'>Puede tardar un minuto.</p>

          <form action="#" className='mt-2' onSubmit={handleSubmit}>

            <br />
            <label className='block text-lg font-Montserrat text-bluef'>Codigo</label>
            <div className='relative'>
              <div className='absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none'>
                <img className=' h-6 pl-3' src={messageAsset} alt='logo' />
              </div>
              <input onChange={handleChangeCode} type="tel" className='border pl-14 focus:ring-blue-500 rounded-full p-2 block' name='Phone Number' placeholder='••••••' required></input>

            </div>
            <p> {error && error} </p>
            <div className='flex w-full justify-center mt-5'>
              <PrincipalButton loading={loading} textI={"Iniciar"} textII={'Procesando'} type={'submit'} />
            </div>

          </form>

        </div>
      </div>
      
      <SetDisplayName showDisplayName={showDisplayName} handleDisplayClose={handleDisplayClose} setDisplayName={setDisplayName} sendReferralCode={sendReferralCode} />
    </>

  )
}

export default CodeConfirmation
import React from 'react'
import statisticsBars from '../Assets/statisticsBars.png'

function Statistics() {
  return (
    <section className=' flex items-center justify-end' >


      <div className='absolute
        leading-5 
        text-lg 

        sm:text-2xl

        lg:text-[40px]
        lg:leading-tight

        left-0  
        p-5 
        xl:p-10
        z-20 
        text-center
        bg-neutral-100 
        rounded-r-lg 
        bg-opacity-75 
        xl:bg-transparent 
        
        max-w-2xl 
        lg:max-w-4xl' >

        <h1 className='
          text-left 
          pb-5
          xl:pb-10 
          font-Montserrat 
          font-semibold
          text-bluef'>Preparamos las evaluaciones por ti, combinando preguntas de distintas fuentes.
        </h1>

        <p className='
          pb-5 
          pl-3
          xl:pb-10 
          xl:pl-10
          font-Montserrat 
          font-semibold
          text-bluef'>O ENURM 2021  -B  <br /> O  ENURM 2022  -C <br /> + Mas
        </p>
        <p className='
          text-left 
          pb-5 
          xl:pb-10 
          font-Montserrat 
          font-semibold
          text-bluef'>Recibirás feedback tan pronto termines la evaluación, de manera que puedas  conocer tus debilidades y reforzarlas a  <span className='text-yellowf'>tiempo</span>.
        </p>
      </div>



      <img className='h-[400px] sm:h-[640px] md:h-[850px] lg:h-[1080px]  z-10' src={statisticsBars} alt='Succes' />
    </section>
  )
}

export default Statistics
import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import { ReactComponent as Lightbul } from '../Assets/lightbulb.svg'

function MiniButton({ icon, padding = 'p-3', loadingApi, handleAnalisisClick }) {

    const [loading, setLoading] = useState(false);
    const timeOut = useRef(null);
    const [clicked, setclicked] = useState(false)


    async function handleClick() {

            setLoading(true)
            setclicked(true)

            await handleAnalisisClick()

            timeOut.current = setTimeout(() => {

                setLoading(false);


            }, 300);

    }

    useEffect(() => {

        if (timeOut.current) return clearTimeout(timeOut.current)

    }, [])



    return (
        <button disabled={loadingApi} onClick={handleClick} className={`${clicked || loadingApi ? 'bg-slate-400' : 'bg-yellowf'} flex justify-center text-lg hover:bg-opacity-80 ${padding} px-6 py-1 rounded-full text-white font-Montserrat font-medium`}>

            <svg className={` animate-spin fill-white ${loading ? '' : 'hidden'}`} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"></path></svg>


            {!loading && <Lightbul/> }
        </button>
    )
}

export default MiniButton
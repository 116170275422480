
import { useEffect, useState } from 'react'
import logo from '../Assets/logo.svg'
import MenuButton from '../Assets/MenuButton.svg'
import CloseMenuButton from '../Assets/CloseMenuButton.svg'
import { Link } from 'react-router-dom'
import { useAuth } from '../Context/AuthContext'
import { useNavigate, useLocation } from 'react-router-dom'


import { useSys } from '../Context/SysContext'

const menu = [
    { id: '/login', name: 'Log in' },
    { id: 'log out', name: 'Log out' },
    { id: '/pricing', name: 'Pricing' },
    { id: '/history', name: 'History' },
    { id: '/about', name: 'About' }
]

const Navbar = () => {

    const [toggle, setToggle] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();

    const { test } = useSys()
    const { currentUser, logout, update } = useAuth()



    function handleNav(optionId) {

        switch (optionId) {
            case '/login':
                if (location.pathname !== 'Login') navigate('/login')
                break;
            case 'log out':
                logout()
                break
            default:
                navigate(optionId)
                break;
        }

    }

    function handleProfileClick() {

        navigate('/profile')
    }

    function updatePage(){


        window.location.reload(true)
    }

    return (

        <nav className='flex flex-col fixed z-40 w-full '>
            <div className={`bg-yellowf flex items-center py-2 px-4 hover:cursor-pointer justify-center text-white font-Montserrat text-lg ${update ? '' : 'hidden' }`} onClick={updatePage}>
                Actualizacion disponible!
            </div>
            <div className='flex p-4 items-center justify-between backdrop-blur-sm  bg-slate-500 bg-opacity-20 xl:bg-opacity-40 '>
                <Link to={'/'}>
                    <img className=' h-[30px] xl:h-[40px]' src={logo} alt="gliaco" />
                </Link>


                <ul className='hidden xl:flex xl:flex-row list-none items-center'>
                    {menu.map((item) => {

                        if ((currentUser && item.id === '/login') || (!currentUser && item.id === 'log out') || (!currentUser && item.id === '/history')) return null

                        return (<li key={item.id} onClick={() => handleNav(item.id)} className='text-whitef text-2xl xl:pl-6 xl:pr-6 font-medium mt-2 xl:mt-0 font-Montserrat hover:cursor-pointer'>
                            {item.name}
                        </li>)

                    })}

                    <li>

                        {
                            currentUser &&
                            <button onClick={handleProfileClick} className=' flex flex-shrink-0 h-10 w-10  bg-center rounded-full  items-center justify-center  text-white font-Montserrat font-bold text-xl' style={{ backgroundImage: `url(${currentUser.photoURL})` }}>
                                <div className='flex flex-shrink-0 h-9 w-9 bg-slate-700 p-1 rounded-full bg-opacity-70  items-center justify-center '>
                                    <p>{currentUser.displayName ? currentUser.displayName.slice(0, 1) : ''}</p>
                                </div>
                            </button>
                        }

                    </li>
                </ul>




                <div className={`${toggle ? 'flex' : 'hidden'} p-4 px-10  bg-neutral-100 rounded-r-lg bg-opacity-95 absolute right-0 top-24 mx-4 mr-6 text-center rounded`}>
                    <ul className='xl:flex flex-col list-none items-center justify-center'>
                        <li className='flex justify-center'>

                            {
                                currentUser &&
                                <button onClick={handleProfileClick} className=' flex flex-shrink-0 h-10 w-10  bg-center rounded-full  items-center justify-center  text-white font-Montserrat font-bold text-xl' style={{ backgroundImage:  `url(${currentUser.photoURL})`  }}>
                                    <div className='flex flex-shrink-0 h-9 w-9 bg-slate-700 p-1 rounded-full bg-opacity-70  items-center justify-center '>
                                        <p>{currentUser.displayName ? currentUser.displayName.slice(0, 1) : ''}</p>
                                    </div>
                                </button>
                            }

                        </li>

                        {menu.map((item) => {

                            if ((currentUser && item.id === '/login') || (!currentUser && item.id === 'log out') || (!currentUser && item.id === '/history')) return null

                            return (<li key={item.id} onClick={() => handleNav(item.id)} className='text-slate-500 text-2xl mt-2 mb-2 font-Montserrat font-medium xl:pr-6 hover:cursor-pointer'>
                                {item.name}
                            </li>)

                        })}


                    </ul>

                </div>


                <div className='flex flex-1 xl:hidden justify-end items-center transition ease-in-out'>
                    <img
                        className={`w-[20px] transition ease-in-out ${toggle ? 'rotate-90' : 'rotate-0'}`}
                        src={toggle ? CloseMenuButton : MenuButton}
                        alt='btn'
                        onClick={
                            () => setToggle((prevToggler) => !prevToggler)
                        } />
                </div>


            </div>


        </nav>
    )
}

export default Navbar
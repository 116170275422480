import React from 'react'
import Navbar from './NavBar'
import Footer from './Footer'

function AppFrame({ children }) {
    return (
        <>
            <div className="flex flex-col h-screen">
                <Navbar />
                <div className=' fixed 
                    left-[50%] 
                    
                    top-[1%] 
                    h-[100px] 
                    w-[100px] 
  
                    rounded-full  
                    bg-purple-700 

                    blur-[100px]
                    md:blur-[150px] 

                    xl:top-[50px] 
                    xl:h-[200px] 
                    xl:w-[200px]
                    z-0' />

                <div className=' fixed 
                    left-[90%] 
                    
                    top-[1%] 
                    h-[100px] 
                    w-[100px] 

                    rounded-full  
                    bg-purple-700 

                    blur-[100px]
                    md:blur-[150px]  

                    xl:top-[50px] 
                    xl:h-[200px] 
                    xl:w-[200px] 
                    z-0' />

                <div className=' fixed 
                    left-[-5%] 

                    top-[90%] 
                    h-[100px] 
                    w-[100px] 
 
                    rounded-full  
                    bg-yellow-500 

                    blur-[100px]
                    md:blur-[150px] 

                    xl:top-[900px] 
                    xl:h-[200px] 
                    xl:w-[200px]
                    z-0' />

                <div className=' fixed 
                    left-[90%] 
                    
                    top-[90%] 
                    h-[100px] 
                    w-[100px]

                    rounded-full  
                    bg-yellow-500 
                    
                    blur-[100px]
                    md:blur-[150px]  

                    xl:top-[900px]  
                    xl:h-[200px] 
                    xl:w-[200px] 
                    z-0' />

                <div className=' fixed 
                    left-[50%] 

                    top-[70%] 
                    h-[100px] 
                    w-[100px] 
 
                    rounded-full  
                    bg-cyan-600 
                    
                    blur-[100px]
                    md:blur-[150px]  

                    xl:top-[800px] 
                    xl:h-[200px] 
                    xl:w-[200px]
                    z-0' />


                {children}

                <Footer/>
            </div>

        </>
    )
}

export default AppFrame
import React from 'react'
import AppFrame from '../Components/AppFrame'
import ExamIteml from '../Components/ExamIteml'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import IniciarExamen from '../Components/IniciarExamen'
import { API_URL } from '../apiConfig'
import { useAuth } from '../Context/AuthContext'
import ErrorDialog from '../Components/ErrorDialog'
import NoFreeEvaluations from '../Components/NoFreeEvaluations'
import { analytics } from '../Firebase'
import { logEvent } from 'firebase/analytics'


function Exam() {

  const [exam, setExam] = useState(null)
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()
  const [error, setError] = useState(null)
  const [showNoFreeEvaluation, setShowNoFreeEvaluations] = useState(false)
  const [referralCode, setReferralCode] = useState(null) 


  const navigate = useNavigate()

  useEffect(() => {

    document.title = `Evaluation - Gliaco`

  },[])


  function handleCleamError() {
    setError(null)
  }


  function handleCloseNoFreeEvaluation() {

    setShowNoFreeEvaluations(false)

  }

  async function finishExam(externalId) {

    logEvent(analytics, 'evaluacion_finalizada')

    try {

      const response = await fetch(`${API_URL}/api/exam/finish/${externalId}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'aplication/json', 'Authorization': `Bearer ${currentUser.accessToken}` }
        })

        if (response.ok) {

          const data = await response.json()

          setExam(null)
  
          navigate(`/evaluation/evaluationresult/${externalId}`, { state: data })

      } else {

          setError({ message: `${response.status} Ha ocurrido un error procesando su solicitud` })

          setLoading(false)

      }

    } catch (error) {

      switch (error.message) {
        case 'Failed to fetch':
          error.message = "No se pudo finalizar el examen, favor validar su conexion"
          break;

        default:
          break;
      }

      setError(error)

    }

  }

  async function createExam() {


    const userDto = {

      Uid: currentUser.uid,
      Name: currentUser.displayName

    }

    setLoading(true)

    try {

      const response = await fetch(`${API_URL}/api/exam/createExam`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${currentUser.accessToken}`},
        body: JSON.stringify(userDto)
      })


      if (response.status === 200) {

        const data = await response.json()
        setExam(data)

        console.log(data)

        logEvent(analytics, 'evaluacion_iniciada')

      } else if (response.status === 202) {

        

        const dataII = await response.json()

        setReferralCode(dataII.referralCode)
        setShowNoFreeEvaluations(true)

      } else {

        setError({ message: `${response.status} Incidente desconocido, favor contactar con soporte.` })

      }

    } catch (error) {

      // const errord = { message: 'Esto es  un error provocado para poder cuadrar el maximo tamaño del recuadro de el error ' }
      switch (error.message) {
        case 'Failed to fetch':
          error.message = "No se pudo iniciar el examen, favor validar conexion"
          break;

        default:
          break;
      }

      setError(error)

    } finally {

      setLoading(false)

    }

  }



  return (
    <>

      {error && <ErrorDialog error={error} secondsN={5} handleCleanError={handleCleamError} />}

      <AppFrame>

        {exam == null ?
          (<IniciarExamen createExam={createExam} loading={loading} />)
          :
          (<ExamIteml exam={exam} finishExam={finishExam} />)}

      </AppFrame>

      <NoFreeEvaluations show={showNoFreeEvaluation} referralCode={referralCode} handleClose={handleCloseNoFreeEvaluation}/>

    </>

  )
}

export default Exam
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { analytics } from '../Firebase'
import { logEvent } from 'firebase/analytics'
import InicialAnimationRotate from './InicialAnimationRotate'
import PrincipalButton from './PrincipalButton'

function ReferralProgramPopup({ show, handleClose }) {

    useEffect(() => {

        logEvent(analytics, 'referral_program_popup')


    },[])

    if (!show) return null

    return (
        <div className='fixed flex inset-0 z-50 justify-center items-center bg-opacity-60 bg-slate-500 '>
            
            <InicialAnimationRotate>
                <div className=' block border rounded-lg p-9 bg-white shadow-lg max-w-xl max-h-screen overflow-auto'>
                    <div className='flex w-full justify-end gap-2'>
                        <button type='submit' onClick={handleClose} className='bg-orange-500 hover:bg-opacity-80  rounded-full text-white font-Montserrat font-bold pl-2 pr-2'>
                            Cerrar
                        </button>
                    </div>

                    <h1 className='font-Montserrat text-xl text-bluef mt-5'>¡Recibe $ 1.00 USD por cada colega que adquiera una suscripción specialist con tu <Link to={"/referrals"} className='underline text-yellowf'>link de referidos</Link>!</h1>

                    <img className='max-h-42 mx-auto' src="https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/ProjectImages%2F26eb563b-5d06-4e2c-9e84-e13d9851d9d4_II.jpg?alt=media&token=90de7733-c524-4645-b58e-fc172c5cd029&_gl=1*1dl2kx9*_ga*MTg4OTc3NzUwNS4xNjc3OTYyMzE0*_ga_CW55HF8NVT*MTY4NjAwNTQwOC44OC4xLjE2ODYwMDU2ODIuMC4wLjA." alt="" />

                    <div className='flex w-full justify-center mt-5'>
                        <PrincipalButton textI={"Ver mi resultado"} textII={'Procesando'} eOnclickc={handleClose} type={'button'} />
                    </div>

                </div>
            </InicialAnimationRotate>
        </div>
    )
}

export default ReferralProgramPopup
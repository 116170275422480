import { initializeApp } from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyD3zHK7RjpXDDHKLoO4lxRaTweR9NfcJrE",
    authDomain: "gliaco.firebaseapp.com",
    projectId: "gliaco",
    storageBucket: "gliaco.appspot.com",
    messagingSenderId: "18669890560",
    appId: "1:18669890560:web:a7ffa33f1dd7f032053ce5",
    measurementId: "G-R7S0TZR9TD"
  };

  
const app = initializeApp(firebaseConfig);
const authConf = getAuth(app)
authConf.languageCode = 'es'

export const analytics = getAnalytics(app);
export const auth = authConf
export const db = getFirestore(app)
export default app
import React from 'react'
import LearnExampleAsset from '../Assets/LearnExampleAsset.png'
import medicineBottle from '../Assets/medicineBottleAsset.png'

function LearnExample() {
    return (
        <section className=' flex items-center justify-start ' >


            <img className='h-[400px] sm:h-[640px] md:h-[850px] lg:h-[1080px]  z-10' src={LearnExampleAsset} alt='Succes' />

            <div className='absolute 
                leading-5 
                text-lg 
        
                sm:text-2xl
                sm:leading-7 
        
                lg:text-[40px]
                lg:leading-10

                xl:leading-tight

                text-center 
                right-0  
                p-5 
                xl:p-10  
                z-20 
                bg-neutral-100 
                rounded-l-lg 
                bg-opacity-75 
                xl:bg-transparent 

                max-w-2xl 
                lg:max-w-4xl' >

                <h1 className='text-left pb-5 xl:pb-10 font-Montserrat font-semibold  text-bluef'>
                    Utilizamos técnicas de <span className='text-yellowf'>aprendizaje</span> visual en conceptos complejos con el objetivo de ayudar a la comprensión y <span className='text-yellowf'>retención</span> de la información.
                </h1>

                <img className='z-10 mx-auto h-[100px] sm:h-[200px] md:h-[250px]' src={medicineBottle} alt='medicine' />

                <div className='text-sm leading-4  sm:text-base sm:leading-5 md:text-lg md:leading-6 lg:text-xl'>
                    <p className=' font-medium text-bluef pt-5 '>
                        Una droga que disminuya o anule la acción de otra droga o sustancia en plasma es un:
                    </p>

                    <p className=' font-medium text-bluef pt-5'>
                        <span className='text-yellowf'>a)</span> Agonista <span className='ml-3' />
                        <span className='text-yellowf'>b)</span> Sinergista <br />
                        <span className='text-yellowf'>c)</span> Inerte <span className='ml-3' />
                        <span className='text-yellowf'>d)</span> Antagonista
                    </p>

                </div>

            </div>

        </section>
    )
}

export default LearnExample
import React, { useEffect, useState } from 'react'
import AppFrame from '../Components/AppFrame'
import CheckSolid from "../Assets/CheckSolid.svg"
import logo from '../Assets/logo.svg'
import { useAuth } from '../Context/AuthContext'
import { API_URL } from '../apiConfig'
import LoadingSpin from '../Components/LoadingSpin'
import { formatDate } from '../Utils/DateUtils'
import ErrorDialog from '../Components/ErrorDialog'
import { Link, useNavigate } from 'react-router-dom'
import InicialAnimationRotate from '../Components/InicialAnimationRotate'

function Profile() {

    const { currentUser } = useAuth()

    const [apiUser, setApiUser] = useState()

    const [error, setError] = useState()

    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {

        document.title = `Profile - Gliaco`

        setLoading(true)

        const getUserTemp = async () => {

            await getUser()
        }

        getUserTemp()

    }, [])

    async function getUser() {

        try {

            const response = await fetch(`${API_URL}/api/user/profile/${currentUser.uid}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'aplication/json', 'Authorization': `Bearer ${currentUser.accessToken}` }
                })

            if (response.ok) {

                const data = await response.json()

                setApiUser(data)

            } else {

                setError({ message: `${response.status}, No fue posible obtener informacion, trabajamos en ello` })

            }



        } catch (error) {

            switch (error.message) {
                case 'Failed to fetch':
                    error.message = "Intentamos obtener los datos respuesta, favor validar conexion"
                    break;

                default:

                    break;
            }

            setLoading(false)
            setError(error)

        }
    }


    function handleSubmit(e) {


        if (!apiUser.planSubscriptions) {

            e.preventDefault()

            navigate('/pricing')

        }

    }


    function handleCleamError() {

        setError(null)
    }

    return (
        <>
            {error && <ErrorDialog error={error} secondsN={5} handleCleanError={handleCleamError} />}
            <AppFrame>

                <InicialAnimationRotate>

                    <section className=' flex flex-col md:flex-row gap-10 items-center justify-center  z-10 mt-20 md:mt-28 '>
                        <div className=' flex flex-col mx-2 z gap-5 text-md border rounded-lg p-9 bg-white shadow-lg z-10  backdrop-blur-md  text-gray-600 font-Montserrat max-w-md'>

                            <img className='w-[150px] mx-auto rounded-full' src={`${currentUser.photoURL}`} alt='logo' />

                            <p>¡Recibe $ 1.00 USD por cada colega que adquiera una suscripción specialist con tu <Link to={"/referrals"} className='underline text-yellowf'>link de referidos</Link>!</p>

                    
                            <div>
                                <h2 className='font-semibold text-lg'>Nombre </h2>
                                <p>{currentUser && currentUser.displayName}</p>
                            </div>

                            <div>
                                <h2 className='font-semibold text-lg'>Fecha de registro </h2>
                                <p>{apiUser ? formatDate(apiUser.createDate) : <LoadingSpin displayLoading={loading} />}</p>
                            </div>

                            <div>
                                <h2 className='font-semibold text-lg'>Plan actual </h2>
                                <p>{apiUser ? apiUser.planName : <LoadingSpin displayLoading={loading} />}</p>
                            </div>

                            <div>
                                <h2 className='font-semibold text-lg'>Fecha de facturacion </h2>

                                <p>{!apiUser ? <LoadingSpin displayLoading={loading} /> : apiUser && apiUser.planSubscriptions ? formatDate(apiUser.planSubscriptions.createDate) : 'N/A'}</p>
                            </div>


                            {
                                apiUser

                                &&

                                <form className='w-full' onSubmit={(e) => handleSubmit(e)} method="POST" action={`${API_URL}/api/PortalApi/create-portal-session`}>
                                    <input id="session_id" type="hidden" name="session_id" value={apiUser.planSubscriptions ? apiUser.planSubscriptions.stripeSessionId : ''} />
                                    <button type="submit" className="text-yellowf border-2 border-yellowf p-2 rounded-md hover:bg-slate-100">
                                        {apiUser.planSubscriptions ? 'Manejar mi subscripción' : 'Adquirir una subscripción'}
                                    </button>
                                </form>
                            }

                            <div>
                                <h2 className='font-semibold'>Contáctanos a nuestro email</h2>
                                <a href="mailto:support@gliaco.com">Support@gliaco.com</a>
                            </div>


                        </div>

                    </section>
                </InicialAnimationRotate>

            </AppFrame>

        </>

    )
}

export default Profile
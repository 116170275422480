import React from 'react'

function BetweenSection(props) {
  return (
    <div className='felx text-center font-Montserrat font-bold  text-bluef p-2  xl:p-10 z-10 leading-5 
    text-lg 

    sm:text-2xl
    sm:leading-7 

    lg:text-[40px]
    lg:leading-10'>
        {props.text}
    </div>
  )
}


export default BetweenSection

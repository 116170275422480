import React from 'react'
import PrincipalButton from '../Components/PrincipalButton'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import InicialAnimationRotate from './InicialAnimationRotate'
import Footer from './Footer'

function IniciarExamen({ createExam, loading }) {


    const navigate = useNavigate()


    return (
        <>
            <div className='flex justify-center items-center mt-20'>
                <InicialAnimationRotate>
                    <div className='rounded-xl border-2 p-5 bg-white z-10 sm:max-w-xl '>
                        <div className={`  text-xl text-center font-Montserrat text-bluef`}>
                            <h1>¿Desea iniciar una nueva evaluación?</h1>

                            <img className='h-[60vw] max-h-[500px] bg-slate-300 mx-auto mt-5 rounded-xl   border-yellowf  border-2' src="https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/ProjectImages%2Fd8efe197-cb15-477f-9bd3-4f5ee173b895.jpg?alt=media&token=fa8f1f0e-cd79-4765-adaf-7d0da79151c5" alt="" />

                            <p className='text-lg mt-5'>
                                Recomendamos que se encuentre en un lugar tranquilo y alejado de las distracciones.
                            </p>

                            <div className="flex flex-col lg:flex-row gap-10 lg:justify-center mt-5 ">


                                <PrincipalButton loading={loading} textI={"Iniciar"} textII={'Procesando'} type={'button'} eOnclickc={createExam} />
                                <button className='bg-slate-400 text-white rounded-full font-medium p-3 px-6 text-lg' onClick={() => { navigate("/") }}>
                                    Cancelar
                                </button>

                            </div>
                            {/* --{loading ? <p className='text-sm mt-2'> Buscando examen en curso</p> : <></>} */}
                        </div>
                    </div>
                </InicialAnimationRotate>

            </div>


        </>

    )
}

export default IniciarExamen
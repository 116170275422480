import React from 'react'
import WelcomeHero from '../Components/WelcomeHero'
import LearnExample from '../Components/LearnExample'
import BetweenSection from '../Components/BetweenSection'
import Statistics from '../Components/Statistics'
import ComingSoon from '../Components/ComingSoon'
import AppFrame from '../Components/AppFrame'
import Footer from '../Components/Footer'
import StripeSection from '../Components/StripeSection'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'


function LandingPage() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)


  useEffect(() => {

    document.title = `Gliaco`

    const referralCode = searchParams.get("referralCode")

    if(referralCode){

      localStorage.setItem('referralCode', referralCode)

    }

  }, [])

  return (
    <>
      <AppFrame>
        <WelcomeHero />
        <BetweenSection text={<h1>"The best way to <span className='text-yellowf'> learn</span> is by doing"</h1>} />
        <LearnExample />
        <BetweenSection text={<h1>"Practice makes <span className='text-yellowf'> masters"</span></h1>} />
        <Statistics />
        <BetweenSection text={<h1>"Pronto"</h1>} />
        <ComingSoon />
        <BetweenSection text={<h1>"Pago online seguro by <span className='text-indigo-500'> Stripe</span>"</h1>} />
        <StripeSection />
        <h2 className='text-center font-Montserrat font-semibold p-10 text-bluef xl:text-[25px]'>
          Queremos ayudarte en tu camino hacia el éxito en el examen de residencia ENURM.<br /> No dudes en contactarnos si tienes alguna pregunta o inquietud. ¡Te deseamos lo mejor en tu preparación!
        </h2>
      </AppFrame>
    </>
  )
}

export default LandingPage
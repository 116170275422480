import React from 'react'
import welcomeHero from '../Assets/welcomeHero.png'
import { useAuth } from '../Context/AuthContext'
import { useNavigate } from 'react-router-dom'
import PrincipalButton from './PrincipalButton'
import InicialAnimationRotate from './InicialAnimationRotate'

function WelcomeHero() {

  const { currentUser } = useAuth()
  const navigate = useNavigate()

  function handleButtonClik() {

    if (currentUser) {

      navigate('/evaluation')

    } else {
      navigate('/login')

    }
  }


  return (

    <section className=' flex items-center justify-end  xl:pt-0'>

      <div className='absolute 
        leading-5 
        text-lg 

        sm:text-2xl
        sm:leading-7 

        lg:text-[40px]
        lg:leading-tight


        left-0  

        p-5 
        xl:p-10  
        
        z-20 
        text-center 
        mt-10 
        bg-neutral-100 
        rounded-r-lg 
        bg-opacity-75     
        max-w-2xl 
        xl:bg-transparent 
        lg:max-w-4xl' >

        <h1 className='
          text-left 
          pb-5
          xl:pb-10 
          font-Montserrat 
          font-black 
          text-bluef'>
          {currentUser ? `Hola ${currentUser.displayName}` : '¡Hola futuro especialista!'}
        </h1>

        <p className='
          text-left 
          pb-5 
          xl:pb-10 
          font-Montserrat 
          font-semibold
          text-bluef'>¡Supera el desafío del examen de residencia <span className='text-yellowf'>ENURM</span> 2024!
        </p>
        <p className='
          text-left 
          pb-5 
          xl:pb-10 
          font-Montserrat 
          font-semibold
          text-bluef'>Con <span className='text-yellowf'>Gliaco</span>, podrás evaluar tus conocimientos y conocer tus posibilidades de aprobar el examen antes de tomarlo.
        </p>

        <InicialAnimationRotate>

          <button onClick={handleButtonClik} className={` ${currentUser ? 'animate-bounce' : ''}  text-sm mt-5 md:text-lg rounded-full bg-yellowf p-4 text-white font-Montserrat xl:text-[20px] shadow-lg hover:shadow-amber-500/50`} >


            {currentUser ? 'Iniciar evaluación' : 'Prueba gratis'}
          </button>

        </InicialAnimationRotate>


      </div>

        <img className='h-[400px] sm:h-[640px] md:h-[850px] lg:h-[1080px]  z-10' src={welcomeHero} alt='Succes' /> 
     


    </section>
  )
}

export default WelcomeHero
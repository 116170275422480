import React from "react";
import { createContext } from "react";
import { useContext } from "react";
import { useEffect } from "react";


const SysStateContext = createContext()

export function useSys() {

    return useContext(SysStateContext)

}

export function SysContext({ children }) {

    


    const sysValues = {

        test: '1321321'
    }

    return (

        <SysStateContext.Provider value={sysValues}>
            {children}
        </SysStateContext.Provider>
    )

}
import React from 'react'
import AppFrame from '../Components/AppFrame'
import { formatDateComplete, dateDifference } from '../Utils/DateUtils'
import { useState, useEffect, useRef } from 'react'
import { API_URL } from '../apiConfig'
import { useAuth } from '../Context/AuthContext'
import LoadingScreen from '../Components/LoadingScreen'
import ErrorDialog from '../Components/ErrorDialog'
import { useNavigate } from 'react-router-dom'
import PrincipalButton from '../Components/PrincipalButton'
import InicialAnimationRotate from '../Components/InicialAnimationRotate'

function EvaluationHistory() {

    const [evaluations, setEvaluations] = useState()
    const { currentUser } = useAuth()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [addingMore, setAddinMore] = useState(false)

    const page = useRef(0)

    const navigate = useNavigate()

    useEffect(() => {

        document.title = `History - Gliaco`

        if (currentUser) {

            const tempGetEval = async () => {

                const data = await getEvaluations(page.current)

                setEvaluations(data)

            }

            tempGetEval()
        }

    }, [])


    async function getEvaluations(page) {

        try {

            const response = await fetch(`${API_URL}/api/exam/user/${page}/${currentUser.uid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${currentUser.accessToken}`
                }

            })
            if (response.ok) {

                const data = await response.json()

                setLoading(false)

                return data

            } else {

                setError({ message: `${response.status} Ha ocurrido un error procesando su solicitud` })

                setLoading(false)

            }

        } catch (error) {

            setError(error)
            setLoading(false)

        }


    }

    function handleCleanError() {

        setError(null)

    }

    function handleClick(externalId) {

        navigate(`/evaluation/evaluationresult/${externalId}`)

    }

    async function handleReintentClick() {

        setLoading(true)
        const tempEvaluation = await getEvaluations(0)
        setEvaluations(tempEvaluation)
        setLoading(false)

    }

    async function handleAddMoreClick() {

        setAddinMore(true)
        const moreEvaluations = await getEvaluations(page.current + 1)
        if (moreEvaluations.length > 0) {

            page.current++


            setEvaluations(preeval => {

                return preeval.concat(moreEvaluations)

            })

        }
        setAddinMore(false)


    }


    return (

        <AppFrame>

            {error && <ErrorDialog error={error} secondsN={5} handleCleanError={handleCleanError} />}

            {loading && !error ?

                <div className='z-10'> <LoadingScreen /></div>
                :

                !evaluations ? <div className='flex justify-center items-center h-full m-2 z-10'> <PrincipalButton eOnclickc={handleReintentClick} type={'button'} textI={'Re-intentar'} textII={'Re-intentando'} loading={loading} /> </div>
                    :

                    evaluations.length === 0 ?

                        <div className='flex justify-center items-center h-full m-2 z-10'> <h1 className='text-xl font-Montserrat text-gray-600'>Aun no tiene evaluaciones finalizadas</h1></div>
                        :

                        (<InicialAnimationRotate>
                            <div className='flex flex-col items-center mt-20 z-10 p-10'>

                                <div className='flex flex-wrap sm:w-[590px] md:w-[750px] lg:w-[1000px] gap-2'>

                                    {evaluations.map((item) => {

                                        return (
                                            <div key={item.id} onClick={() => handleClick(item.externalId)}>



                                                <div className='bg-white rounded-lg border p-5 hover:cursor-pointer w-60 flex flex-col'>
                                                    <div className='flex flex-col items-center'>

                                                        <h3 className='text-xl text-bluef'>Puntuación!</h3>
                                                        <div className='flex text- items-center justify-center mt-5 border-8 rounded-full p-2 border-x-green-400 h-20 w-20'>
                                                            <h2 className='text-3xl text-center text-gray-600'> {item.score}</h2>

                                                        </div>

                                                    </div>
                                                    <div className='mt-5 text-gray-600'>
                                                        <h1 className='font-semibold'>Fecha</h1>
                                                        <h2 className=''>{formatDateComplete(item.createdDate)}</h2>
                                                        <br />
                                                        <h1 className='font-semibold'>Duración</h1>
                                                        <h2 className=''>{dateDifference(item.createdDate, item.endDate)} Min</h2>
                                                    </div>

                                                </div>

                                            </div>
                                        )
                                    })}
                                </div>

                                <div className='mt-5 z-10'><PrincipalButton eOnclickc={handleAddMoreClick} textI={'Mas'} textII={'Procesando'} type={'button'} loading={addingMore} /></div>

                            </div>
                        </InicialAnimationRotate>)
            }
        </AppFrame>
    )
}

export default EvaluationHistory
import React, { useEffect } from 'react'
import { useState } from 'react'
import userAsset from '../Assets/userAsset.svg'
import { useNavigate } from 'react-router-dom'
import PrincipalButton from './PrincipalButton'
import { API_URL } from '../apiConfig'
import { useRef } from 'react'
import { useAuth } from '../Context/AuthContext'



function SetDisplayName({ showDisplayName, handleDisplayClose, setDisplayName, sendReferralCode }) {


    const [userName, setUserName] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const referralCode = useRef()
    const navigate = useNavigate()

    function handleUserNameInputChange(e) {

        setUserName(e.target.value)

    }

    useEffect(()=>{

        referralCode.current = localStorage.getItem('referralCode')

    },[])


    async function handleSubmit(e) {
        e.preventDefault()

        setLoading(true)

        try {

            await setDisplayName(userName)

            if(referralCode.current)
            {
                await sendReferralCode(referralCode.current)

            }           
            
            setLoading(false)
            navigate("/")

        } catch (error) {
            
            switch (error.code) {
                case 'auth/network-request-failed' || 'auth/internal-error':
                    setError("Sin conexión")
                    break;
            
                default:
                    setError("Intente mas tarde")
                    setLoading(false)
                    break;
            }
            
        }


    }

    if (!showDisplayName) return null
    
    return (


        <div className='fixed flex inset-0 z-50 justify-center items-center bg-opacity-60 bg-slate-500'>

            <div className=' block border rounded-lg p-9 bg-white shadow-lg '>
                <div className='flex w-full justify-end gap-2'>
                    <button type='submit' onClick={handleDisplayClose} className='bg-orange-500 hover:bg-opacity-80  rounded-full text-white font-Montserrat font-bold pl-2 pr-2'>
                        Cerrar
                    </button>
                </div>

                <h1 className='font-Montserrat text-lg text-bluef mt-5'>Como te llamamos?<br /></h1>

                <form action="#" className='mt-5' onSubmit={handleSubmit}>

                    <label className='block text-lg font-Montserrat text-bluef'>Nombre</label>
                    <div className='relative'>
                        <div className='absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none'>
                            <img className=' h-6 pl-3' src={userAsset} alt='logo' />
                        </div>
                        <input onChange={handleUserNameInputChange} type="text" className='border pl-14 focus:ring-blue-500 rounded-full p-2 block' name='Name' placeholder='Your name' required maxLength={50}></input>
                    </div>


                    <p> {error && error} </p>

                    <div className='flex w-full justify-center mt-5'>
                        <PrincipalButton loading={loading} textI={"Enviar"} textII={'Procesando'}/>
                    </div>

                </form>

            </div>
        </div>

    )
}

export default SetDisplayName
import React from 'react'
import comingSoon from '../Assets/mockupWhatsappAsset.png'

function ComingSoon() {
  return (
    <section className=' flex items-center justify-start' >

          


            <img className='h-[400px] sm:h-[640px] md:h-[850px] lg:h-[1080px]  z-10' src={comingSoon} alt='Succes' />

            <div className='absolute 
              leading-5 
              text-lg 

              sm:text-2xl
              sm:leading-7 

              lg:text-[40px]
              lg:leading-tight
              
              text-center 
              right-0  
              p-5 
              xl:p-10  
              z-20 
              mt-10 
              bg-neutral-100 
              rounded-r-lg 
              bg-opacity-75
              xl:bg-transparent 
              max-w-2xl 
              xl:max-w-4xl' >

                <h1 className='
                    text-left 

                    pb-5
                    xl:pb-10 
                    font-Montserrat 
                    font-semibold
                    text-bluef'>¡Estamos emocionados de anunciar que próximamente estaremos llevando tu aprendizaje al siguiente nivel  con el lanzamiento de Gliaco en <span className='text-yellowf'>Whatsapp</span>!
                </h1>
            </div>

        </section>
  )
}

export default ComingSoon
import React from 'react'
import { useEffect, useState, useRef } from 'react'
import AppFrame from '../Components/AppFrame'
import logo from '../Assets/logo.svg'

function About() {

    const [animation, setanimate] = useState('rotate-y-45 opacity-80')

    const timeoutRef = useRef(null)

    useEffect(() => {

        document.title = `About - Gliaco`

        const timeOut = setTimeout(() => {

            setanimate('')

        }, 100);

        return () => { clearTimeout(timeOut); }
    }, [])

    function handleClick() {

        setanimate('rotate-y-45 opacity-80')

        if (timeoutRef.current) clearTimeout(timeoutRef.current)

        timeoutRef.current = setTimeout(() => {

            setanimate('')

        }, 300);

    }

    return (
        <AppFrame>
            <div className={`${animation} transition-all duration-300 ease-in-out transform perspective-1000 flex justify-center items-center  text-center`}>
                <div className='mt-24 max-w-5xl text-left mb-20 bg-white rounded-lg border-2 p-5 m-2'>
                    {/* <h1 className='text-4xl font-Montserrat text-bluef text-center font-semibold'>We are Gliaco</h1> */}
                    <img className='h-16 mx-auto' src={logo} alt="" />
                    <img className=' h-[50vw] max-h-[400px] mx-auto border-2 rounded-lg border-yellowf mt-5' src="https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/ProjectImages%2Fe_61f2f0ea-6dd3-435a-8bd1-2b50bf8c30ab.jpg?alt=media&token=fb8875e2-d509-45bb-a6d0-0b108aea76cc" alt="" />
                    <div className=' text-gray-600 text-lg font-Montserrat'>
                        <br />
                        <p>Bienvenidos a Gliaco, una innovadora startup localizada en orlando EEUU dedicada al aprendizaje online y enfocada en apoyar a los estudiantes que se preparan para el examen ENURM. Nos enorgullecemos de ser una plataforma de vanguardia que utiliza la inteligencia artificial para generar información y facilitar el proceso de estudio diario de nuestros usuarios. </p>
                        <br />
                        <p>En Gliaco, entendemos que cada estudiante es único y tiene diferentes necesidades y habilidades. Por eso, hemos diseñado una plataforma que se adapta a cada individuo y ofrece funcionalidades específicas para optimizar su experiencia de aprendizaje.</p>
                        <br />
                        <p>Una de nuestras principales características son las simulaciones de evaluaciones, donde los estudiantes pueden poner a prueba sus conocimientos y familiarizarse con el formato del examen. Esto les permite identificar áreas de mejora y adaptar su estrategia de estudio en consecuencia.</p>
                        <br />
                        <p>Además, reconocemos que la memoria visual es una herramienta poderosa en el proceso de aprendizaje. Por eso, en Gliaco creamos imágenes relacionadas con los enunciados para facilitar la retención y el recuerdo de la información. Esta estrategia de aprendizaje basada en imágenes permite a nuestros usuarios asociar conceptos y datos de manera más efectiva y duradera.</p>
                        <br />
                        <p>En Gliaco, nos esforzamos por ofrecer una plataforma de aprendizaje integral y eficiente que se adapte a las necesidades de cada estudiante y les permita alcanzar sus metas académicas. Estamos comprometidos con el éxito de nuestros usuarios y trabajamos constantemente para mejorar y expandir nuestra oferta de servicios.</p>
                        <br />
                        <p>Únete a Gliaco hoy y descubre cómo nuestra plataforma de aprendizaje online basada en inteligencia artificial puede ayudarte a prepararte de manera efectiva para el examen ENURM y alcanzar tus objetivos académicos. ¡Juntos, haremos que el éxito esté a tu alcance!</p>

                    </div>
                </div>
            </div>
        </AppFrame>

    )
}

export default About
import React, { useEffect } from "react";

import { API_URL } from "../apiConfig";
import AppFrame from "../Components/AppFrame";
import CheckSolid from "../Assets/CheckSolid.svg"
import logo from '../Assets/logo.svg'
import EnvelopeRegular from '../Assets/EnvelopeRegular.svg'
import { useAuth } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import InicialAnimationRotate from "../Components/InicialAnimationRotate";
import { Analytics } from "firebase/analytics";
import { logEvent } from 'firebase/analytics'
import { analytics } from "../Firebase";

function Pricing() {

    const { currentUser } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {

        document.title = `Pricing - Gliaco`

    },[])


    function handleClickFree() {

        if(!currentUser){

            navigate('/login')

        }else{

            navigate('/evaluation')
        }
        

    }

    function handleSubmit(e) {

        if(!currentUser){

            e.preventDefault()

            navigate('/login')

        }else
        {

            logEvent(analytics,'begin_checkout')
        }

        
        
    }

    return (
        <AppFrame>
             <InicialAnimationRotate>
            <section className=' flex flex-col md:flex-row gap-10 items-center justify-center z-10 mt-20 md:mt-56 '>

               
                <div className=' flex flex-col z gap-5 mx-2 text-md border rounded-lg p-9 bg-white shadow-lg z-10  backdrop-blur-md  text-gray-600 font-Montserrat max-w-md'>

                    <img className='w-[150px] mx-auto' src={logo} alt='logo' />

                    <div className="flex justify-between items-center">
                        <h2 className="font-semibold text-lg">Specialist plan</h2>
                        <p className="border-2 px-2 rounded-full text-yellowf ">Popular</p>

                    </div>

                    <p>El plan más popular y completo, definido y hecho para futuros especialistas.</p>

                    <p><span className="text-4xl font-semibold">$43.94 </span> / 6 month</p>

                    <div className="flex flex-row items-center">
                        <img className="h-6 mr-4" src={CheckSolid} alt="" />
                        <p>Evaluaciones ilimitadas</p>
                    </div>

                    <div className="flex flex-row items-center">
                        <img className="h-6 mr-4" src={CheckSolid} alt="" />
                        <p>Consultas AI ilimitadas</p>
                    </div>

                    <div className="flex flex-row items-center">
                        <img className="h-6 mr-4" src={CheckSolid} alt="" />
                        <p>Resúmenes ilimitados</p>
                    </div>

                    <div className="flex flex-row items-center">
                        <img className="h-6 mr-4" src={CheckSolid} alt="" />
                        <p>Soporte técnico</p>
                    </div>

                    <form className="flex flex-col " onSubmit={(e) => handleSubmit(e)} action={`${API_URL}/api/CheckoutApi/create-checkout-session`} method="POST">

                        <input id="uid" type="hidden" name="uid" value={currentUser ? currentUser.uid : ''} />
                        <button id="checkout-and-portal-button" type="submit" className="bg-yellowf text-white p-2 rounded-md hover:opacity-80">
                            {currentUser ? "Checkout" : "Login"}
                        </button>
                    </form>

                </div>
                <div className=' flex flex-col mx-2 z gap-5 text-md border rounded-lg p-9 bg-white shadow-lg z-10  backdrop-blur-md  text-gray-600 font-Montserrat max-w-md'>

                    <img className='w-[150px] mx-auto hidden' src={logo} alt='logo' />

                    <div className="flex justify-between items-center">
                        <h2 className="font-semibold text-lg">Free plan</h2>

                    </div>

                    <p>Un plan creado para que puedas evaluarte sin invertir.</p>

                    <p><span className="text-4xl font-semibold">Free </span> / month</p>

                    <div className="flex flex-row items-center">
                        <img className="h-6 mr-4" src={CheckSolid} alt="" />
                        <p>3 evaluaciones / month</p>
                    </div>

                    <div className="flex flex-row items-center">
                        <img className="h-6 mr-4 " src={CheckSolid} alt="" />
                        <p>1 consulta AI / Evaluación</p>
                    </div>

                    <div className="flex flex-row items-center">
                        <img className="h-6 mr-4" src={CheckSolid} alt="" />
                        <p>Soporte técnico</p>
                    </div>

                    <button onClick={handleClickFree} className="text-yellowf border-2 border-yellowf p-2 rounded-md hover:opacity-80">
                        {currentUser ? 'Inicia un examen' : 'Log in / Sign up'}
                    </button>


                </div>
            </section>
            </InicialAnimationRotate>
            


        </AppFrame>

    )
}

export default Pricing
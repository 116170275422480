import React, { useState } from 'react'
import { useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import AppFrame from '../Components/AppFrame'
import { API_URL } from '../apiConfig'
import { dateDifference, formatDate } from '../Utils/DateUtils'
import ErrorDialog from '../Components/ErrorDialog'
import CorrectIcon from '../Assets/CorrectIcon.svg'
import IncorrectIcon from '../Assets/IncorrectIcon.svg'
import LoadingScreen from '../Components/LoadingScreen'
import PrincipalButton from '../Components/PrincipalButton'
import MiniButton from '../Components/MiniButton'
import AnswerContent from '../Components/AnswerContent'
import InicialAnimationRotate from '../Components/InicialAnimationRotate'
import { useNavigate } from 'react-router-dom'
import lightbul from '../Assets/lightbulb.svg'
import { useAuth } from '../Context/AuthContext'
import ReferralProgramPopup from '../Components/ReferralProgramPopup'

function ExamResults() {

  const location = useLocation()

  const [examResult, setExamResult] = useState(null)
  const { externalId } = useParams();
  const [error, setError] = useState(null)
  const [loadingApi, setLoadingApi] = useState(false)
  const [screenLoading, setScreenLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const { currentUser } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {

    document.title = `Evaluation Result - Gliaco`

    const getSetExam = async () => {

      setScreenLoading(true)

      const data = await getExam(externalId)

      setExamResult(data)

      setScreenLoading(false)

    }

    if (!loadingApi) {

      getSetExam()

    }


  }, [location.state, externalId])

  useEffect(() => {

    const getQuantityTemp = async () => {

      const data = await getQuantity(currentUser.uid)

      if (data) {

        if (data.quantity === 1) {

          setShow(true)

        }
        //console.log(data)


      }

    }

    if (!loadingApi) {

      getQuantityTemp()

    }

  }, [])

  function handleCleamError() {

    setError(null)

  }

  async function getExam(externalId) {

    try {

      const response = await fetch(`${API_URL}/api/exam/${externalId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'aplication/json', 'Authorization': `Bearer ${currentUser.accessToken}` }

      })

      if (response.ok) {

        const data = await response.json()

        //console.log(data)
        return data

      } else {

        setError({ message: `${response.status} Ha ocurrido un error procesando su solicitud` })

        setScreenLoading(false)

        return null

      }

    } catch (error) {

      switch (error.message) {

        case 'Failed to fetch':
          error.message = 'No se pudo consultar el examen, favor  validar su conexion '
          break;

        default:
          break;

      }

      setError(error)
      return null
    }

  }


  const updateAnswareInfo = (itemIndex, answareInfo) => {


    const updatedExamItems = [...examResult.examItems];

    updatedExamItems[itemIndex] = {

      ...updatedExamItems[itemIndex],
      ['answerInfo']: answareInfo.info,

    };

    setExamResult({ ...examResult, examItems: updatedExamItems });
  };

  async function getAnswareInfo(questionId, answareId, itemIndex) {

    try {

      const response = await fetch(`${API_URL}/api/questioninfo/${questionId}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'aplication/json', 'Authorization': `Bearer ${currentUser.accessToken}` }
        })

      console.log(currentUser.accessToken)
      if (response.ok) {

        const data = await response.json()



        updateAnswareInfo(itemIndex, data)

      } else {

        setError({ message: `${response.status} Ha ocurrido un error procesando su solicitud` })

        setScreenLoading(false)

      }


    } catch (error) {


      switch (error.message) {

        case 'Failed to fetch':
          error.message = 'No se pudo consultar el examen, favor  validar su conexion '
          break;

        default:
          break;
      }

      setError(error)

    }

  }

  async function handleAnalisisClick(questionId, answerId, itemIndex) {

    setLoadingApi(true)

    await getAnswareInfo(questionId, answerId, itemIndex)

    setLoadingApi(false)

  }

  async function handleReintentClick() {

    setLoading(true)
    const data = await getExam(externalId)

    setExamResult(data)
    setLoading(false)

  }

  function handleNewEvaluationClick() {

    navigate('/evaluation')

  }

  function handleClose() {

    setShow(false)

  }

  async function getQuantity(uid) {

    try {

      const response = await fetch(`${API_URL}/api/exam/quantity/${uid}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${currentUser.accessToken}` }

      })

      if (response.ok) {

        const data = await response.json()

        return data

      } else {

        console.error(response.status)
        return null

      }

    } catch (error) {

      console.error(error.message)
      return null

    }

  }

  return (

    <>
      {error && <ErrorDialog error={error} secondsN={5} handleCleanError={handleCleamError} />}
      <ReferralProgramPopup show={show} handleClose={handleClose} />
      <AppFrame>

        {screenLoading === true ?
          <div className='z-10'><LoadingScreen /></div>
          :
          examResult === null ?
            <div className='flex justify-center items-center h-full m-2 z-20'> <PrincipalButton eOnclickc={handleReintentClick} type={'button'} textI={'Re-intentar'} textII={'Re-intentando'} loading={loading} /> </div>
            :
            (<div className=' mt-20 flex  justify-center '>
              <div className='flex flex-col items-center srounded-md z-10 p-2 xl:w-[60%] text-lg font-Montserrat text-gray-500 '>

                <div className='lg:grid lg:grid-cols-3 lg:gap-4 border-2 rounded-md bg-white p-5 text-center items-center w-full '>

                  <h1 className='col-span-3 text-center text-xl text-bluef'>Examen finalizado</h1>

                  <div>
                    <h3 className='text-xl mb-4 mt-5 text-bluef'>Información</h3>
                    <div className='flex flex-row justify-center'>
                      <div className='text-right text-gray-400 text-lg '>
                        <h3>Usuario</h3>
                        <h3>Fecha</h3>
                        <h3>Total</h3>
                      </div>

                      <div className='w-1 h-auto bg-slate-700 mx-2'></div>

                      <div className='text-left text-xl'>
                        <h3>{examResult.userName}</h3>
                        <h3 >{formatDate(examResult.createdDate)}</h3>
                        <h3 >{examResult.examItems.length}</h3>
                      </div>
                    </div>
                  </div>

                  <div className='flex flex-col items-center'>
                    <h3 className='text-xl mt-5 text-bluef'>Puntuación!</h3>
                    <div className='flex items-center justify-center mt-5 border-8 rounded-full p-2 border-x-green-400 h-28 w-28'>
                      <h2 className='text-5xl text-center'>  {examResult.score}</h2>
                    </div>

                  </div>


                  <div>
                    <h3 className='text-xl mb-4 mt-10 text-bluef'>Datos</h3>
                    <div className='flex flex-row justify-center'>
                      <div className='text-right text-lg text-gray-400'>
                        <h3 >Duracion</h3>
                        <h3 >Correctas</h3>
                        <h3 >Incorrectas</h3>
                      </div>

                      <div className='w-1 h-auto bg-bluef mx-2'></div>

                      <div className='text-left text-xl'>
                        <h3> {dateDifference(examResult.createdDate, examResult.endDate)} min</h3>
                        <h3>{examResult.examItems.filter(item => item.isCorrect === true).length}</h3>
                        <h3>{examResult.examItems.filter(item => item.isCorrect === false).length}</h3>
                      </div>
                    </div>
                  </div>

                </div>

                {
                  examResult.examItems.map(((item, itemIndex) => {
                    return (

                      <div key={item.id} className='flex flex-col mt-5 transition-all duration-300 ease-in-out transform w-full'>

                        <div className=' border-2 rounded-md bg-white p-3 divide-y'>

                          <div className='flex lg:flex-row-reverse flex-col mb-2 items-center'>

                            <img className='h-14 w-14 rounded-full mr-5 lg:ml-auto mb-2' src={item.imageUrl} alt="" />
                            <p className='mr-2 text-xl text-bluef'>{item.questionTitle}</p>

                          </div>

                          {item.question.bibliography &&

                            <div className='flex lg:flex-row flex-col items-center'>

                              <span className='text-sm text-slate-500'>{item.question.bibliography}</span>

                            </div>

                          }


                          <div className='flex flex-col divide-y  lg:flex-row lg:divide-x lg:divide-y-0 lg:flex lg:flex-rowlg:divide-x lg:pt-2 pt-0 '>

                            {!item.isCorrect ?

                              <>
                                <div className={'flex lg:w-[50%] items-center pt-2 lg:pt-0 '}>
                                  <img className='h-8 mr-2' src={IncorrectIcon} alt="" />


                                  <p>{
                                    `${item.answerTitle}`}</p>
                                </div>

                                <div className='flex flex-1 px-2   items-center justify-center order-last lg:order-none pt-2 mt-2 lg:pt-0 lg:mt-0 '>

                                  <MiniButton icon={lightbul} padding={'p-0'} loadingApi={loadingApi} handleAnalisisClick={() => handleAnalisisClick(item.questionId, item.answerId, itemIndex)} />

                                </div>

                                <div className='flex lg:w-[50%] lg:pl-2  items-center  pt-2 mt-2 lg:pt-0 lg:mt-0 ' >
                                  <AnswerContent answer={item.question.answers[0].title} />
                                </div>
                              </>

                              :

                              <>
                                <div className={'flex lg:w-[100%] items-center mt-2 '}>
                                  <img className='h-8 mr-2' src={CorrectIcon} alt="" />
                                  <p>{`${item.answerTitle}`}</p>
                                </div>
                              </>
                            }

                          </div>

                          {
                            item.answerInfo
                            &&

                            <div className='mt-2 pt-5 text-bluef'>
                              <InicialAnimationRotate>
                                <h2 > {item.answerInfo} </h2>
                                <span className='text-sm text-slate-500'>By Gliaco AI</span>
                              </InicialAnimationRotate>
                            </div>

                          }

                        </div>
                      </div>)

                  }))
                }

                <div className='mt-5 z-10'> <PrincipalButton textI={'Nueva evaluación'} textII={'Procesando'} type={'button'} eOnclickc={handleNewEvaluationClick} ></PrincipalButton>  </div>
              </div>
            </div>
            )}
      </AppFrame >
    </>

  )
}

export default ExamResults
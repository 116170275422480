import React, { useEffect } from 'react'
import AppFrame from '../Components/AppFrame'
import { useAuth } from '../Context/AuthContext'
import { useNavigate } from 'react-router-dom'
import InicialAnimationRotate from '../Components/InicialAnimationRotate'
import { analytics } from '../Firebase'
import { logEvent } from 'firebase/analytics'

function PaymentSuccess() {

    const { currentUser } = useAuth()
    const navigate = useNavigate()


    useEffect(() => {

        document.title = `Payment Success - Gliaco`

        logEvent(analytics,'purchase')

    },[])


    function handleButtonClik() {

        if (currentUser) {

            navigate('/evaluation')

        } else {
            navigate('/login')

        }
    }


    return (
        <AppFrame>
            <div className={`flex justify-center items-center h-full  text-center z-10`}>
                <InicialAnimationRotate>
                    <div className='mt-24 max-w-5xl mb-20 bg-white rounded-lg border-2 p-5 m-2 text-center'>
                        <h1 className='text-4xl font-Montserrat text-bluef text-center font-semibold'>Bienvenido al plan especialista!</h1>

                        <img className=' h-[50vw] max-h-[400px] mx-auto  mt-5' src="https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/ProjectImages%2F9bbc6718-eafa-4d9e-ac3e-1d37c268963c.png?alt=media&token=687bde9a-8b56-46de-870f-d90515eeec55" alt="" />

                        <button onClick={handleButtonClik} className={` ${currentUser ? 'animate-bounce' : ''}  text-sm mt-10 md:text-lg rounded-full bg-yellowf p-4 text-white font-Montserrat xl:text-[20px] shadow-lg hover:shadow-amber-500/50`} >


                            {currentUser ? 'Iniciar evaluación' : 'Prueba gratis'}
                        </button>
                    </div>
                </InicialAnimationRotate>
            </div>
        </AppFrame>
    )
}

export default PaymentSuccess
import React from 'react'
import { useState, useEffect } from 'react';

function PrincipalButton({loading, textI, textII, type, eOnclickc, padding = 'p-3'}) {

    const [displayLoading, setDisplayLoading] = useState(false);
    

    useEffect(() => {
        let timeout;

        if (loading) {

            setDisplayLoading(true);

        } else {

            timeout = setTimeout(() => {
                setDisplayLoading(false);
            }, 300); // Minimum display time for the loading state (in milliseconds)

        }

        return () => {
            clearTimeout(timeout);
        };

    }, [loading]);

    return (
        <button disabled={displayLoading} type={type} onClick={eOnclickc}  className={`${displayLoading ? 'bg-slate-400 ' : 'bg-yellowf'} flex justify-center text-lg hover:bg-opacity-80 ${padding} px-6 rounded-full text-white font-Montserrat font-medium`}>

            <svg className={`mr-2 animate-spin fill-white ${displayLoading ? '' : 'hidden'}`} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"></path></svg>

            {displayLoading ? textII : textI}
        </button>
    )
}

export default PrincipalButton
import React, { useState } from 'react'
import PrincipalButton from './PrincipalButton'
import { useNavigate } from 'react-router-dom'
import InicialAnimationRotate from './InicialAnimationRotate'
import { useEffect } from 'react'
import { analytics } from '../Firebase'
import { logEvent } from 'firebase/analytics'
import ToastMessage from './ToastMessage'

function NoFreeEvaluations({ show, referralCode, handleClose }) {

    const navigate = useNavigate()
    const [showToast, setShowToast] = useState(false)

    useEffect(() => {

        logEvent(analytics, 'no_free_evaluarions')

    }, [])

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: "title",
                    text: "text",
                    url: "url",
                });
                console.log('Content shared successfully');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.error('Web Share API is not supported in this browser');
        }
    };

    const copyToClipboard = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          setShowToast(true)
        } catch (err) {
          console.error('No se pudo copiar el texto: ', err);
        }
      };

    function handleClick() {

        navigate('/pricing')

    }

    function handleCloseToast(){

        setShowToast(false)

    }

    if (!show) return null

    return (

        <div className='fixed flex inset-0 z-50 justify-center items-center bg-opacity-60 bg-slate-500 '>
            {showToast && <ToastMessage handleClose={handleCloseToast} message={'Copiado!'} secondsN={3}/>}
            <InicialAnimationRotate>
                <div className=' block border rounded-lg p-9 bg-white shadow-lg max-w-xl max-h-screen overflow-auto'>
                    <div className='flex w-full justify-end gap-2'>
                        <button type='submit' onClick={handleClose} className='bg-orange-500 hover:bg-opacity-80  rounded-full text-white font-Montserrat font-bold pl-2 pr-2'>
                            Cerrar
                        </button>
                    </div>

                    <h1 className='font-Montserrat text-xl text-bluef mt-5'>No cuentas con evaluaciones disponibles este mes en tu plan actual.</h1>

                    <img className='max-h-42 mx-auto' src="https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/ProjectImages%2F5e01c28e-0832-4260-9376-6fdd7f1cb84f.png?alt=media&token=79a3d9d0-63a9-4f05-86a0-aabfb5160d08" alt="" />

                    <h2 className='text-bluef font-Montserrat'>Comparte tu enlace de referidos con colegas y recibe una evaluación gratis por cada usuario registrado.</h2>
                    {referralCode && <p className='text-bluef underline p-2 border rounded-md hover:cursor-pointer ' onClick={() => copyToClipboard(`https://gliaco.com/?referralCode=${referralCode}`)} >{`https://gliaco.com/?referralCode=${referralCode}`}</p> }
                    
                    
                    <div className='flex w-full justify-center mt-5'>
                        <PrincipalButton textI={"Ver planes"} textII={'Procesando'} eOnclickc={handleClick} type={'button'} />
                    </div>

                </div>
            </InicialAnimationRotate>
        </div>

    )

}

export default NoFreeEvaluations
import React from 'react'

function Footer() {
  return (
    <footer className='flex h-full items-end'>
        <div className='xl:flex xl:justify-between p-5 text-center font-Montserrat font-normal text-sm xl:text-lg w-full'>
            
            <p>Powered with <span className='text-yellowf'>❤</span> by Gliaco Dominicana</p>
            <p>Copyright © 2023 Gliaco - Todos los derechos reservados.</p>
        </div>
    </footer>
  )
}

export default Footer
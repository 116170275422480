import React from 'react'

function LoadingScreen() {
  return (
    <div className={`flex justify-center items-center h-screen text-center`}>

                <svg xmlns="http://www.w3.org/2000/svg"  width="200px" height="200px" viewBox="0 0 100 100">
                    <circle cx="34" cy="50" fill="#f5a540" r="16">
                        <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="34;66;34" begin="-0.5s"></animate>
                    </circle>
                    <circle cx="66" cy="50" fill="#384a7b" r="16">
                        <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="34;66;34" begin="0s"></animate>
                    </circle>
                    <circle cx="34" cy="50" fill="#f5a540" r="16">
                        <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="34;66;34" begin="-0.5s"></animate>
                        <animate attributeName="fill-opacity" values="0;0;1;1" calcMode="discrete" keyTimes="0;0.499;0.5;1" dur="1s" repeatCount="indefinite"></animate>
                    </circle>
                </svg>
            </div>
  )
}

export default LoadingScreen


function dateDifferenceUtil(date1, date2) {
  // Convert both dates to milliseconds
  const timestamp1 = new Date(date1).getTime();
  const timestamp2 = new Date(date2).getTime();

  // Calculate the difference in milliseconds
  const diffInMilliseconds = Math.abs(timestamp2 - timestamp1);

  // Convert the difference to days, hours, minutes, and seconds
  const diffInMinutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
  

  return diffInMinutes
}

function formatDateUtil(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

function formatDateUtilComplete(dateString) {
  const date = new Date(dateString); // date with time in 24-hour format
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true };
  return date.toLocaleString('en-US', options);
}


export const dateDifference = dateDifferenceUtil
export const formatDate = formatDateUtil
export const formatDateComplete = formatDateUtilComplete
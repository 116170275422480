import React, { useEffect, useState } from 'react'
import AppFrame from '../Components/AppFrame'
import CheckSolid from "../Assets/CheckSolid.svg"
import logo from '../Assets/logo.svg'
import { useAuth } from '../Context/AuthContext'
import { API_URL } from '../apiConfig'
import LoadingSpin from '../Components/LoadingSpin'
import { formatDate } from '../Utils/DateUtils'
import ErrorDialog from '../Components/ErrorDialog'
import { useNavigate } from 'react-router-dom'
import InicialAnimationRotate from '../Components/InicialAnimationRotate'
import ToastMessage from '../Components/ToastMessage'

function Referrals() {

    const { currentUser } = useAuth()

    const [apiUser, setApiUser] = useState()

    const [error, setError] = useState()

    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()

    const [showToast, setShowToast] = useState(false)

    useEffect(() => {

        document.title = `Referrals - Gliaco`

        setLoading(true)

        const getUserTemp = async () => {

            await getUser()
        }

        getUserTemp()

    }, [])

    async function getUser() {

        try {

            const response = await fetch(`${API_URL}/api/user/referral/${currentUser.uid}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'aplication/json', 'Authorization': `Bearer ${currentUser.accessToken}` }
                })

            if (response.ok) {

                const data = await response.json()
                console.log(data)
                setApiUser(data)

            } else {

                setError({ message: `${response.status}, No fue posible obtener informacion, trabajamos en ello` })

            }



        } catch (error) {

            switch (error.message) {
                case 'Failed to fetch':
                    error.message = "Intentamos obtener los datos respuesta, favor validar conexion"
                    break;

                default:

                    break;
            }

            setLoading(false)
            setError(error)

        }
    }


    function handleCleamError() {

        setError(null)
    }

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setShowToast(true)
        } catch (err) {
            console.error('No se pudo copiar el texto: ', err);
        }
    };

    function handleCloseToast() {

        setShowToast(false)

    }

    return (
        <>
            {error && <ErrorDialog error={error} secondsN={5} handleCleanError={handleCleamError} />}
            {showToast && <ToastMessage handleClose={handleCloseToast} message={'Copiado!'} secondsN={3} />}
            <AppFrame>

                <InicialAnimationRotate>

                    <section className=' flex flex-col md:flex-row gap-10 items-center justify-center  z-10 mt-20 md:mt-28 '>
                        <div className=' flex flex-col mx-2 z gap-5 text-md border rounded-lg p-9 bg-white shadow-lg z-10  backdrop-blur-md  text-gray-600 font-Montserrat max-w-md'>

                            <h1 className=' text-xl text-center font-Montserrat text-bluef'>Aquí encontraras información relacionada a los usuarios referidos y tu balance actual.</h1>

                            <div>
                                <h2 className='font-semibold text-lg'>Balance </h2>
                                <p>$ {apiUser ? apiUser.balance  : <LoadingSpin displayLoading={loading} />} USD</p>
                            </div>

                            <div>
                                <h2 className='font-semibold text-lg'>Link de referidos</h2>
                                {apiUser ? <p onClick={() => copyToClipboard(`https://gliaco.com/?referralCode=${apiUser.referralCode}`)} >{`https://gliaco.com/?referralCode=${apiUser.referralCode}`}</p> : <LoadingSpin displayLoading={loading} />}
                            </div>

                            <div>
                                <h2 className='font-semibold text-lg'>Usuarios referidos </h2>
                                <p>{apiUser ? apiUser.referralUsers : <LoadingSpin displayLoading={loading} /> }</p>
                            </div>

                            <div>
                                <h2 className='font-semibold text-lg'>Fecha de ultimo retiro </h2>
                                <p>{apiUser ? !apiUser.lastWithdrawal ? "N/A" : formatDate(apiUser.lastWithdrawal) : <LoadingSpin displayLoading={loading} />}</p>
                            </div>

                            <p>Los retiros únicamente pueden ser procesados con un balance mayor a 20.00 USD.</p>

                            <p>Los depósitos serán realizados a su cuenta en Banco de Reservas de la República Dominicana "Banreservas".</p>

                            <p>Solo aplica para usuarios que registren una nueva cuenta con su Link de referidos y adquieran el plan en no mas de 30 días.</p>

                            <div>
                                <h2 className='font-semibold'>Contáctanos a nuestro email</h2>
                                <a href="mailto:support@gliaco.com">Support@gliaco.com</a>
                            </div>


                        </div>

                    </section>
                </InicialAnimationRotate>

            </AppFrame>

        </>
    )
}

export default Referrals
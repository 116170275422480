
import LandingPage from './Pages/LandingPage'
import Login from './Pages/Login'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthContext } from './Context/AuthContext'
import Exam from './Pages/Exam';
import ProtecteRoute from './Components/ProtecteRoute';
import About from './Pages/About';
import './index.css'
import ExamResults from './Pages/ExamResults';
import Pricing from './Pages/Pricing';
import PaymentSuccess from './Pages/PaymentSuccess';
import PaymentCanceled from './Pages/PaymentCanceled';
import Profile from './Pages/Profile';
import EvaluationHistory from './Pages/EvaluationHistory';
import { SysContext } from './Context/SysContext';
import NotFound from './Pages/NotFound';
import Referrals from './Pages/Referrals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement:  <NotFound/>
  },
  {
    path: "login/",
    element: <Login />
  },
  {
    path: "evaluation/",
    element: <ProtecteRoute>  <Exam />  </ProtecteRoute>
  },
  {
    path: "about/",
    element: <About />
  },
  {
    path: "pricing/",
    element: <Pricing />
  },
  {
    path: "history/",
    element: <ProtecteRoute> <EvaluationHistory /></ProtecteRoute>
  },
  {
    path: "pricing/paymentsuccess/",
    element: <ProtecteRoute><PaymentSuccess /></ProtecteRoute>
  },
  {
    path: "pricing/canceled/",
    element: <PaymentCanceled />
  },
  {
    path: "profile/",
    element: <ProtecteRoute> <Profile /> </ProtecteRoute>
  },
  {
    path: "evaluation/evaluationresult/:externalId",
    element: <ProtecteRoute>   <ExamResults />   </ProtecteRoute>
  },
  {
    path: "referrals",
    element: <ProtecteRoute><Referrals/></ProtecteRoute> 
  }

])

function App() {
  return (
    <SysContext>
      <AuthContext>
        <RouterProvider router={router} />
      </AuthContext>
    </SysContext>

  );
}

export default App;

import React from "react";
import { useState, useEffect } from "react";

function ToastMessage({handleClose, message, secondsN}) {

    const [seconds, setSeconds] = useState(0)
    const [opacity, setOpacity] = useState('opacity-0')

    useEffect(() => {
        //seconds no existe dentro del contexto de un intervalo en react          
        setTimeout(() => {
            setOpacity('opacity-100')
        }, 100);

        const interval = setInterval(() => {


            setSeconds(preseconds => preseconds + 1)

        }, 1000);

        const timeOut = setTimeout(() => {

            handleClose()

        }, (secondsN * 1000));

        return () => {
            clearInterval(interval)
            clearTimeout(timeOut)
        }

    }, [])

    return (
        <>
            {
                <div className={`fixed z-30 transition-opacity ${opacity}  duration-500 `}>
                    <div className='flex w-screen justify-center mt-20'>
                        <div className='bg-slate-400 p-5 border-2 rounded-lg max-w-lg'>
                            <h1 className='text-white text-lg'>{message}</h1>
                            {/* <p className='text-white text-right' >{(secondsN - seconds)}</p> */}
                        </div>
                    </div>
                </div>

            }
        </>
    )

}

export default ToastMessage
import React from 'react'
import AppFrame from '../Components/AppFrame'
import { useRouteError } from 'react-router-dom'

function NotFound() {

    const error = useRouteError()
    console.error(error)

    return (

        <AppFrame>
            <div className='flex flex-col justify-center items-center h-screen font-Montserrat' >

                <h1 className=' font-extrabold text-8xl text-slate-600 z-20'>Oops!</h1>
                <p className='text-2xl text-slate-600 z-20 mt-5'>Algo no anda bien.</p>
                <p className='mt-5'>{error.statusText || error.message}</p>
            </div>
        </AppFrame>

    )
}

export default NotFound
import React, { useState } from 'react'
import { useAuth } from '../Context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import LoadingScreen from './LoadingScreen'
import AppFrame from './AppFrame'

function ProtecteRoute({ children }) {

    const { currentUser, starting } = useAuth()
    const [logOut, setLogOut] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (!starting) {

            if(!currentUser)
            {

                navigate('/login')

            }
        }
    }, [currentUser, starting])

    return (
        <>
            {!starting && currentUser ? children : <LoadingScreen/> }
        </>
    )
}

export default ProtecteRoute
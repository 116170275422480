import React from 'react'

function StripeSection() {
  return (
    <div className='flex justify-center flex-row gap-4'>
        <img className='h-8 md:h-20' src="https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/PayMethodImg%2F58482363cef1014c0b5e49c1.png?alt=media&token=d54fd2d0-0107-4dce-8f2d-8708d42b577e" alt="" />
        
        
        <img className='h-8 md:h-20' src="https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/PayMethodImg%2F58482354cef1014c0b5e49c0.png?alt=media&token=bc275d1d-3627-4d8f-997c-ee83c1851537" alt="" />
        
        <img className='h-8 md:h-20' src="https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/PayMethodImg%2F620670d4d7b91b0004122618.png?alt=media&token=79109872-2c7b-4141-bc39-d0fc98c673b1" alt="" />
        
        <img className='h-8 md:h-20' src="https://firebasestorage.googleapis.com/v0/b/gliaco.appspot.com/o/PayMethodImg%2F616057c476000b00045a7da2.png?alt=media&token=3d524bdf-f6e3-4764-836f-3d34abc7618c" alt="" />

    </div>
  )
}

export default StripeSection
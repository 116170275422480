import React, { useState, useContext, useEffect, useRef } from 'react'
import { createContext } from 'react'
import { auth } from '../Firebase'
import { signInWithPhoneNumber, RecaptchaVerifier, updateProfile } from 'firebase/auth'
import { API_URL } from '../apiConfig'
import { analytics } from '../Firebase'
import { logEvent } from 'firebase/analytics'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from '../Firebase'
import { getRandomImg } from '../Utils/ProfileImg'

const AuthStateContext = createContext()
const LocalVersion = '1.1.0'

export function useAuth() {
    return useContext(AuthStateContext)
}

export function AuthContext({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const [update, setUpdate] = useState(false)
    const [starting, setStarting] = useState(true)

    const tempUser = useRef(null)

    const setUpRecaptcha = (handleSubmit) => {
        window.recaptchaVerifier = new RecaptchaVerifier('recapcha-container', {
            'size': 'invisible'


        }, auth);

    }

    async function sigInUser(phone, appVerifier) {

        await signInWithPhoneNumber(auth, phone, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).

                window.confirmationResult = confirmationResult;

            }).catch((error) => {
                // Error; SMS not sent
                throw error
            });
    }

    async function codeConfirmation(code) {

        let userDisplayName = null

        try {

            const result = await window.confirmationResult.confirm(code)

            userDisplayName = result.user.displayName

            await postUserApi(result.user.phoneNumber, result.user.uid, result.user.accessToken)

            if (userDisplayName) {

                setCurrentUser(result.user);

                logEvent(analytics, 'login')

            } else {

                logEvent(analytics, 'sign_up')
                tempUser.current = result.user

            }


        } catch (error) {

            throw error
        }

        return userDisplayName
    }

    async function postUserApi(phoneNumber, uid, accessToken) {

        const user = {
            id: 0,
            companyId: 1,
            createDate: new Date(),
            name: 'Aun no sabemos tu nombre!',
            telefono: phoneNumber,
            active: true,
            uid: uid,
            lastLogin: new Date(),
            planId: 1,
            planName: "Free"
        };


        try {

            const response = await fetch(`${API_URL}/api/user/Sigin`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' ,'Authorization': `Bearer ${accessToken}`},
                    body: JSON.stringify(user)
                })

            if (!response.ok) {

                throw `${response.status} ${response.statusText}`

            }

        } catch (error) {

            throw error
        }

        // await fetch(`${API_URL}/api/user/Sigin`, 
        //     {
        //         method: 'POST',
        //         headers: {'Content-Type': 'application/json'},
        //         body: JSON.stringify(user)
        //     })
        //   .then(response => response.json())
        //   .then(data => console.log(data))
        //   .catch((error) => {

        //     throw error
        //   })

    }

    async function setDisplayName(userName) {

        const userDto = {

            Uid: tempUser.current.uid,
            Name: userName

        }

        try {

            const response = await fetch(`${API_URL}/api/user/DisplayName`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tempUser.current.accessToken}`},
                    body: JSON.stringify(userDto)
                })

            if (!response.ok) {

                throw `${response.status} ${response.statusText}`

            }


            const result = await updateProfile(auth.currentUser, { displayName: userName, photoURL: getRandomImg() })



            setCurrentUser({ ...tempUser.current, displayName: userName })

        } catch (error) {

            throw console.error(error)

        }
    }

    async function sendReferralCode(referralCode){

        try {

            const referredDto = {

                UID: tempUser.current.uid,
                referralCode: referralCode

            }
            
            const response = await fetch(`${API_URL}/api/ReferralSignIn/saveReferral`, {

                method: 'POST',
                headers: {'Content-Type': 'application/json',  'Authorization': `Bearer ${tempUser.current.accessToken}`},
                body: JSON.stringify(referredDto)
            })

            if(response.ok){
                console.log("Todo bien")
            }

            
        } catch (error) {
            throw error
        }


    }

    function logout() {
        auth.signOut()
        setCurrentUser(null)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {

            if (user) {

                if (user.displayName) {

                    setCurrentUser(user)
                    setStarting(false)
                }
            } else {
                setCurrentUser(null)
                setStarting(false)

            }


        })

        return unsubscribe
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {

          if (currentUser) {
            
            currentUser.getIdToken(true)
            console.log(currentUser.accessToken)   

          }
        }, 30 * 60 * 1000); // 50 minutes

         
        // Clear interval on component unmount
        return () => clearInterval(interval);
      }, [currentUser]);

    useEffect(() => {

        let unSub

        if (currentUser && !unSub) {

            unSub = onSnapshot(doc(db, 'Gliaco', 'Publish'),
                (doc) => {

                    console.log(doc.data().Version)                    
                    console.log(LocalVersion)
                   

                    if(doc.data().Version !== LocalVersion){

                        console.log('true ')

                        setUpdate(true)
                        
                    }else
                    {

                        setUpdate(false)
                    }


                }, (error) => {

                    console.log("Error")
                    unSub()
                }
            )
        } else if(unSub)
        {
            unSub()
        }

        return () => unSub
    }, [currentUser])

    const value = {
        setUpRecaptcha,
        currentUser,
        sigInUser,
        codeConfirmation,
        logout,
        setDisplayName,
        sendReferralCode,
        update,
        starting
    }

    return (
        <AuthStateContext.Provider value={value}>
            {children}
        </AuthStateContext.Provider>
    )
}
